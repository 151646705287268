<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />

    <div>
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <div :class="`text-`+lang.lalgin" style="padding-inline:30px;">
            <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" 
                :style="`width:150px;background:transparent;border:none;white-space: nowrap !important;`" 
                @click="addCOA()"
                v-b-toggle.add_new_account>
              <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_new_account}}</div></b-button>
        </div>
        <v-row style="padding-inline:40px;">
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack text-center">{{lang.account}}</th>
                            <th class="backBlack text-center">{{lang.account_name}}</th>
                            <th class="backBlack text-center">{{lang.account_type}}</th>
                            <th class="backBlack text-center">{{lang.default_type}}</th>
                            <th class="backBlack text-center">{{lang.action}}</th>
                        </tr>
                        <tr v-if="parent.length != 0 && accountrows.length != 0" style="align-items: center;">
                            <th colspan="5">
                                <div style="display:flex;flex-direction:row;justify-content:space-between;items-align:center;">
                                    <b-button 
                                        class="btn btn-sm"
                                        :style="`width:150px;background:black;color:#fff !important;font-size:1.3em;`+lang.algin+`:10px;`" 
                                        >
                                        {{classname}}
                                    </b-button>
                                    <b-button 
                                    class="btn btn-sm"
                                    :style="`background:black;color:#fff !important;font-size:1.3em;`+lang.algin+`:10px;`" 
                                    @click="getReport(classid)">
                                    <i :class="`fas fa-arrow-`+lang.algin" style="color:#fff !important;"></i>
                                    {{ lang.back }}
                                </b-button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="viewMe" v-for="(item,index) in accountrows" :key="index">
                            <td class="text-center" style="direction:ltr">{{item.classid}}</td>
                            <td class="text-center" v-html="lang.langname == 'ar' ? item.namear : item.nameen"></td>
                            <td class="text-center" v-html="lang.langname == 'ar' ? item.account_type_ar : item.account_type_en"></td>
                            <td class="text-center" v-html="lang.langname == 'ar' ? item.default_ar : item.default_en"></td>
                            <td class="text-center" style="width:490px;">
                                <div style="display:flex;flex-direction:row;justify-content:center;" v-if="item.id != 0">
                                    <div
                                        @click="editme(item)"
                                        v-b-toggle.add_new_account
                                        style="cursor:pointer;margin:1px;background:#000;color:#fff;width:115px;">
                                        {{lang.update}}
                                    </div>
                                    <div 
                                        v-if="item.subs.length != 0"
                                        style="cursor:pointer;margin:1px;background:rgb(0, 82, 24);color:#fff;width:115px;font-weight:bolder" @click="getReport(item.classid)">
                                        {{ lang.sub_accounts }}
                                    </div>
                                    <div
                                        v-if="item.type != 2 && item.integrated == 1"
                                        @click="addsupCOA(item)"
                                        v-b-toggle.add_new_account
                                        style="cursor:pointer;font-size:.8rem;margin:1px;background:rgb(0, 2, 97);color:#fff;width:115px;font-weight:bolder">
                                        {{ lang.add_sub_account }}
                                    </div>
                                    <div
                                        v-if="item.integrated == 1"
                                        @click="del(item.classid)"
                                        style="cursor:pointer;margin:1px;background:red;color:#fff;width:115px;">
                                        {{lang.delete}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
        <div style="padding:3px;">يرجى الانتظار ... Please Wait</div>
      </div>
        <Footer />
        <vue-snotify></vue-snotify>
        <coa-addnew ref="addCOA" />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import {SnotifyPosition} from 'vue-snotify'
import CoaAddnew from '@/components/coa-addnew.vue';
import ExportsPrint from '@/components/exports-print.vue'
export default {
    components: { breadCrumbs, TabsComp, HeaderPortrate, Footer, CoaAddnew,ExportsPrint },
    data() {
        return {
            expensrows: [],
            accountrows: [],
            accounts: [],
            account: 0,
            account_name: '',
            classid: 0,
            parent: {},
        }
    },
    created() {
        this.getReport(this.classid);
    },
    methods: {
        addCOA(){
            this.$refs.addCOA.resetvalues();

            this.$refs.addCOA.pagename = this.lang.add_new_account;
            this.$refs.addCOA.btnname = this.lang.add;
        },
        addsupCOA(item){
            this.$refs.addCOA.current = {
                id: 0,
                namear: '',
                nameen: '',
                classid: '',
                parentid: item.classid,
                parent_name: item.namear,
                account_type: 1,
                type: 1,
                default: 1,
                integrated: 1,
                expenses: 0
            }
            this.$refs.addCOA.getMain(item.parentid),
            this.$refs.addCOA.pagename = this.lang.add_new_account;
            this.$refs.addCOA.btnname = this.lang.add;
        },
        editme(item){
            this.$refs.addCOA.getAllInte(),
            this.$refs.addCOA.current = item,
            this.$refs.addCOA.getMain(item.parentid),
            this.$refs.addCOA.getName(item.classid),
            this.$refs.addCOA.pagename = this.lang.update_acount;
            this.$refs.addCOA.btnname = this.lang.update;
        },
        // changeit(item){
        //     // this.classid = item.parentid;
        //     // alert([this.classid]);
        //     // this.classid = item.classid;
        //     // this.classname = this.lang.langname == 'ar' ? item.namear : item.nameen;
        // },
        del(classid){
            const post = new FormData();
            post.append('type',"delChartOfAccount");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[calssid]',classid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                let message = res.results.data;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.getReport(this.classid);
            })
        },
        getReport(classid){
            this.$store.state.showLoading = true;
            const post = new FormData();
            post.append('type',"getChartOfAccount");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[calssid]',classid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                this.$store.state.showLoading = false;
                const res = response.data;
                
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data.results;
                    this.classid = res.results.data.parent.length != 0 ? res.results.data.parent.parentid : 0;
                    this.parent = res.results.data.parent;
                }
            })
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        classname:function(){
            if(this.parent.length == 0){
                return '';
            }
            if(this.lang.langname == "ar"){
                return this.parent.namear;
            }else{
                return this.parent.nameen;
            }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.chart_of_account,
                    disabled: true,
                    to: '/finance/coa',
                }
        },
       
    },
}
</script>
<style>
.viewMe{
    transition: visibility 0s 2s, opacity 2s linear;
    align-items: center;
}
</style>